import { Layout } from 'components/layout';
import React from 'react';
import { Router } from '@reach/router';
import { DestinationPage } from 'modules/destinations';
import { DestinationItem } from 'modules/destinations/destination-details/DestinationItem';
import { useIntl } from 'gatsby-plugin-react-intl';
import { usePrismicDocuments } from 'hooks';

const Destinations: React.FC = () => {
  const { locale } = useIntl();
  const documents = usePrismicDocuments(locale, 'destination');

  return (
    <Layout isGhostNav={false}>
      <Router>
        <DestinationPage
          path={`/${locale}/destinations/`}
          documents={documents}
        />
        <DestinationItem
          path={`/${locale}/destinations/:uid`}
          documents={documents}
        />
      </Router>
    </Layout>
  );
};

export default Destinations;
